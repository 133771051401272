import {Injectable, NgZone} from '@angular/core'
import {CanActivate, CanLoad, Router, UrlTree} from '@angular/router'
import {Observable} from 'rxjs'
import {ConfigService, SpbConfiguration} from '../services/config.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  private config: SpbConfiguration = {} as any

  constructor(
    private configService: ConfigService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.configService.configState$.subscribe({
      next: (config: SpbConfiguration) => this.config = config
    })
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.test()
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.test()
  }

  private test(): boolean | Promise<boolean> {
    return this.config.rtUser || this.config.rtAdmin || this.ngZone.run(() => this.router.navigate(['login'])).then()
  }


}
