/**
 * Used wit the sign dialog to be able to present different
 * messages for different flows (login/sign)
 */
export interface SignDialogData {
  /**
   * The order ref to handle.
   */
  orderRef: string

  /**
   * Type of BankID action. Either 'sign' or 'login'
   */
  type: string // 'sign' or 'login'

  /**
   * Title of dialog
   */
  title: string

  time: number

  /**
   * The text to show when the user should start Bankid
   */
  startText: string

  /**
   * The text to show when the user should enter her code.
   */
  actionText: string

  /**
   * Text to show when sign/identify is completed.
   */
  doneText: string

  /**
   * QR Code data from BankID
   */
  startResponse: StartResponse

  /**
   * Start on other device, id est. show QR code
   */
  other: boolean
}

export interface StartResponse {
  /**
   * An array of strings to use for showing QR codes.
   */
  authArray: string[]

  /**
   * The  token to start BankID app on this device.
   */
  autoStartToken: string

  /**
   * We use this to collect
   */
  orderRef: string

  /**
   * Used in QR only
   */
  qrStartToken: string
}

/**
 * Interface for interest types
 */
export interface InterestType {
  itemId: string
  interestTypeKey: string
  interestTypeName: string
}

/**
 * Usage type from Aws
 */
export interface GetUsageType {
  endDate: string
  items: { value: [number, number] }
  position: string
  startDate: string
  usagePlanId: string
}

/**
 * Random variables used in the app
 */
export const HOME_ROUTE_PATH = 'home'
export const LOGIN_ROUTE_PATH = 'login'
export const USER_ROLE = 'rtAppUser'
export const IS_RT_ADMIN_ROLE = 'rtAppAdmin'

/**
 * This is the timer used for closing the snackbar for saved/deleted roles/users.
 */
export const TIME_OUT = 3000
